export const PATH_WAY = [
  { text: 'All Projects', color: 'grey darken-2' },
  { text: 'Agriculture & Natural Resources', color: 'grey darken-2' },
  { text: 'Arts, Media & Entertainment', color: 'grey darken-2' },
  { text: 'Building & Construction Trades', color: 'grey darken-2' },
  { text: 'Business & Finance', color: 'grey darken-2' },
  { text: 'Education, Childhood Development & Family Services', color: 'grey darken-2' },
  { text: 'Energy, Environment & Utilities', color: 'grey darken-2' },
  { text: 'Engineering & Architecture', color: 'grey darken-2' },
  { text: 'Fashion & Interior Design', color: 'grey darken-2' },
  { text: 'Health Science & Medical Technology', color: 'grey darken-2' },
  { text: 'Hospitality, Tourism & Recreation', color: 'grey darken-2' },
  { text: 'Information & Communication Technologies', color: 'grey darken-2' },
  { text: 'Manufacturing & Product Design', color: 'grey darken-2' },
  { text: 'Marketing, Sales & Service', color: 'grey darken-2' },
  { text: 'Public Services', color: 'grey darken-2' },
  { text: 'Transportation', color: 'grey darken-2' }
];

export const PATHWAY_OPTIONS = [
  // 'All Employers',
  'All',
  'Agriculture & Natural Resources',
  'Arts, Media & Entertainment',
  'Building & Construction Trades',
  'Business & Finance',
  'Education, Childhood Development & Family Services',
  'Energy, Environment & Utilities',
  'Engineering & Architecture',
  'Fashion & Interior Design',
  'Health Science & Medical Technology',
  'Hospitality, Tourism & Recreation',
  'Information & Communication Technologies',
  'Manufacturing & Product Design',
  'Marketing, Sales & Service',
  'Public Services',
  'Transportation'
];

export const TIME_LINES = [
  'Explore programs',
  'Build employer projects',
  'Auto-apply for internships',
  'Interviews',
  'Position Offers',
  'Internships'
];

export const PROGRAMS = [
  'Computer Science',
  'Engineering',
  'Digital Media',
  'Public Service',
  'Transportation',
  'Business & Finance',
  'Education & Family',
  'Energy & Sustainability',
  'Healthcare'
];

export const USER_ROLES = {
  student: { val: 'Student', color: 'green', show: false },
  teacher: { val: 'Teacher', color: 'pink', show: true },
  school: { val: 'School', color: 'blue', show: true },
  parent: { val: 'Parent', color: 'yellow', show: true },
  employer: { val: 'Employer', color: 'purple', show: true },
  sponsor: { val: 'Sponsor', color: 'red', show: true }
};
