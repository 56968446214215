






































































































































































































































































































































































































































































































































































































































































import store, { useUserGetters, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { PATHWAY_OPTIONS } from '@/constants/landing';
import moment from 'moment';

export default defineComponent({
  name: 'MNameAndMetaSettings',
  components: {
    UploadImage: () => import('@/components/molecules/settings/m-upload-image.vue'),
    MBannerImages: () => import('@/components/molecules/settings/m-banner-images.vue'),
    ADatePicker: () => import('@/components/atoms/ADatePicker.vue')
  },
  props: {
    programId: {
      type: String,
      required: true
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    isTemplate: {
      type: Boolean,
      default: false,
      required: false
    },
    isDraft: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  setup(props: any, ctx: any) {
    const { getUser, getProgramDoc } = useUserGetters(['getUser', 'getProgramDoc']);
    const { saveMetaSettings, getPathways } = useUserActions(['saveMetaSettings', 'getPathways']);

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const docValue: any = ref('program');
    if (props?.isTemplate) {
      docValue.value = 'template';
    }
    const pathwayOptions = ref(PATHWAY_OPTIONS);
    const pathwaySearch = ref(null);
    const industryOptions = ref([]);
    const industrySearch = ref(null);
    const keywordOptions = ref([]);
    const keywordSearch = ref(null);
    const tagOptions = ref([]);
    const tagSearch = ref(null);

    const docName = ref('');
    const docId = ref();
    const docDesc = ref('');
    const shareableLink = ref('');
    const pathways = ref('');
    const industries = ref('');
    const visibility = ref('Please Select Visibility');
    const visibilities = ref(['Please Select Visibility', 'private', 'public']);
    const imageOption = ref('Please Select Image Option');
    const imageOptions = ref(['Please Select Image Option', 'Select Image', 'Upload Image']);
    const selectImage = ref(false);
    const uploadImage = ref(false);
    const selectedImageValue: any = ref('');
    const selectedImageDialog = ref(false);
    const docImage = ref('');

    const metaDesc = ref('');
    const keywords = ref('');
    const tags = ref('');
    const ogTitle = ref('');
    const ogUrl = ref('');
    const ogDesc = ref('');
    const ogImage = ref('');
    const twitterTitle = ref('');
    const twitterUrl = ref('');
    const twitterDesc = ref('');
    const twitterImage = ref('');
    const linkedinTitle = ref('');
    const linkedinUrl = ref('');
    const linkedinDesc = ref('');
    const linkedinImage = ref('');
    const isShowTimer = ref(false);
    const timerType = ref('Please Select Timer Type');
    const timerOptions = ref([
      'Please Select Timer Type',
      'Count Up',
      'Deadline Completion',
      'Count Down'
    ]);
    const todayDate = ref(moment().format('YYYY-MM-DD'));
    const deadlineCompletion = ref('');

    const getPathwaysOptions = async () => {
      const pathwaysResultValue: any = await getPathways();
      if (pathwaysResultValue) {
        industryOptions.value = pathwaysResultValue?.pathways
          .filter(pathway => pathway?.type === 'industries')
          .map(pathway => pathway?.name);
      }
    };

    if (!props.isTemplate) {
      getPathwaysOptions();
    }

    const createShareableUrl = (docName, docId) => {
      let program_name = docName.toString().toLowerCase();
      // replace & with and
      program_name = program_name.split(/&+/).join('-and-');
      // remove invalid characters
      program_name = program_name.split(/[^a-z0-9]/).join('-');
      // remove duplicates
      program_name = program_name.split(/-+/).join('-');
      // trim leading & trailing characters
      program_name = program_name.trim('-');

      let origin;
      if (process.env.VUE_APP_NODE_ENV === 'stage') {
        origin = 'stage.pilotcity.com';
      } else if (process.env.VUE_APP_NODE_ENV === 'production') {
        origin = 'pilotcity.com';
      } else {
        origin = 'develop.pilotcity.com';
      }

      const sharable_url = `${origin}/${
        props?.isTemplate ? 'template' : 'program'
      }/${program_name}-${docId}`;

      return sharable_url;
    };

    const setLinkValue = (docName, docId) => {
      if (docName) {
        shareableLink.value = createShareableUrl(docName, docId);
      } else {
        shareableLink.value = docName;
      }
    };

    const user = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const getImageBase64 = (val, type) => {
      if (type === 'docImage') {
        docImage.value = val;
      } else if (type === 'ogImage') {
        ogImage.value = val;
      } else if (type === 'twitterImage') {
        twitterImage.value = val;
      } else if (type === 'linkedinImage') {
        linkedinImage.value = val;
      }
    };

    const removeImage = (val, type) => {
      if (type === 'docImage') {
        docImage.value = '';
        selectedImageValue.value = '';
      } else if (type === 'ogImage') {
        ogImage.value = '';
      } else if (type === 'twitterImage') {
        twitterImage.value = '';
      } else if (type === 'linkedinImage') {
        linkedinImage.value = '';
      }
    };

    const getDocData = async () => {
      const document = getProgramDoc.value;
      // program settings
      docName.value = props?.isTemplate ? document?.templateName : document?.programName;
      docId.value = props?.isTemplate ? document?._id?.toString() : document?._id?.toString();
      shareableLink.value = document?.sharable_url;
      docDesc.value = props?.isTemplate ? document?.templateDesc : document?.programDesc;
      pathways.value = document?.pathways;
      industries.value = document?.industries;
      docImage.value = props?.isTemplate ? document?.templateImage : document?.programImage;
      selectedImageValue.value = document?.docImage;

      // activity timer settings
      if (document?.isShowTimer) isShowTimer.value = document?.isShowTimer;
      if (document?.timerType) timerType.value = document?.timerType;
      if (document?.deadlineCompletion) deadlineCompletion.value = document?.deadlineCompletion;

      // meta settings
      metaDesc.value = document?.metaData?.metaDesc;
      keywords.value = document?.metaData?.keywords;
      tags.value = document?.metaData?.tags;

      ogTitle.value = document?.metaData?.ogTitle;
      ogUrl.value = document?.metaData?.ogUrl;
      ogDesc.value = document?.metaData?.ogDesc;
      ogImage.value = document?.metaData?.ogImage;

      twitterTitle.value = document?.metaData?.twitterTitle;
      twitterUrl.value = document?.metaData?.twitterUrl;
      twitterDesc.value = document?.metaData?.twitterDesc;
      twitterImage.value = document?.metaData?.twitterImage;

      linkedinTitle.value = document?.metaData?.linkedinTitle;
      linkedinUrl.value = document?.metaData?.linkedinUrl;
      linkedinDesc.value = document?.metaData?.linkedinDesc;
      linkedinImage.value = document?.metaData?.linkedinImage;
      visibility.value = document?.visibility || 'Please Select Visibility';
    };

    getDocData();

    const saveLoading = ref(false);
    const saveSettings = async () => {
      saveLoading.value = true;
      let documentType = props?.isTemplate ? 'ProgramSchema' : 'Program';
      if (props?.isDraft) {
        documentType = 'ProgramRevision';
      }
      const payload: any = {
        documentType,
        programId: props?.programId?.toString(),
        sharable_url: shareableLink.value,
        metaDesc: metaDesc.value,
        keywords: keywords.value,
        tags: tags.value,
        ogTitle: ogTitle.value,
        ogUrl: ogUrl.value,
        ogDesc: ogDesc.value,
        twitterTitle: twitterTitle.value,
        twitterUrl: twitterUrl.value,
        twitterDesc: twitterDesc.value,
        linkedinTitle: linkedinTitle.value,
        linkedinUrl: linkedinUrl.value,
        linkedinDesc: linkedinDesc.value,
        visibility: visibility.value
      };

      if (props.isTemplate) {
        payload.templateName = docName.value;
        payload.templateDesc = docDesc.value;
        if (docImage.value !== null || selectedImageValue.value !== null) {
          payload.templateImage = docImage.value || selectedImageValue.value;
        }
      } else {
        payload.programName = docName.value;
        payload.programDesc = docDesc.value;
        payload.pathways = pathways.value;
        payload.industries = industries.value;
        if (docImage.value !== null || selectedImageValue.value !== null) {
          payload.programImage = docImage.value || selectedImageValue.value;
        }
      }

      if (ogImage.value !== null) {
        payload.ogImage = ogImage.value;
      }
      if (twitterImage.value !== null) {
        payload.twitterImage = twitterImage.value;
      }
      if (linkedinImage.value !== null) {
        payload.linkedinImage = linkedinImage.value;
      }
      if (timerType?.value !== 'Please Select Timer Type') {
        payload.timerType = timerType?.value;
        if (timerType?.value === 'Deadline Completion' && deadlineCompletion?.value) {
          payload.deadlineCompletion = deadlineCompletion?.value;
        }
      }
      payload.isShowTimer = isShowTimer?.value;
      const result = await saveMetaSettings(payload);
      if (result.status === 403) {
        setTimeout(() => {
          saveLoading.value = false;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: result.data.error.description,
            type: 'error',
            isShowSnackbar: true
          });
        }, 2000);
      } else if (result) {
        getDocData();
        props.fetchProgram();
        setTimeout(() => {
          saveLoading.value = false;
          store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
            message: 'Program Settings saved successfully',
            type: 'success',
            isShowSnackbar: true
          });
        }, 2000);
      }
    };

    if (docImage.value && imageOption.value === 'Please Select Image Option') {
      selectedImageValue.value = docImage.value;
      selectedImageDialog.value = true;
    }

    watch(imageOption, () => {
      if (imageOption.value === 'Select Image') {
        selectImage.value = true;
        uploadImage.value = false;
      } else if (imageOption.value === 'Upload Image') {
        selectedImageDialog.value = false;
        selectedImageValue.value = undefined;
        selectImage.value = false;
        uploadImage.value = true;
      } else {
        if (docImage.value) {
          selectedImageValue.value = docImage.value;
          selectedImageDialog.value = true;
        }
        selectImage.value = false;
        uploadImage.value = false;
      }
    });

    const closeDialog = () => {
      imageOption.value = 'Please Select Image Option';
    };

    const setSelctedImage = selectedImage => {
      selectedImageDialog.value = true;
      selectedImageValue.value = selectedImage;
      imageOption.value = 'Please Select Image Option';
    };

    return {
      capitalizeFirstLetter,
      docValue,
      pathwayOptions,
      pathwaySearch,
      industryOptions,
      industrySearch,
      keywordOptions,
      keywordSearch,
      tagOptions,
      tagSearch,
      docName,
      docId,
      docDesc,
      shareableLink,
      metaDesc,
      pathways,
      industries,
      keywords,
      tags,
      setLinkValue,
      saveLoading,
      saveSettings,
      user,
      ogTitle,
      ogUrl,
      ogDesc,
      ogImage,
      twitterTitle,
      twitterUrl,
      twitterDesc,
      twitterImage,
      linkedinTitle,
      linkedinUrl,
      linkedinDesc,
      linkedinImage,
      getImageBase64,
      removeImage,
      docImage,
      imageOption,
      imageOptions,
      selectImage,
      uploadImage,
      closeDialog,
      setSelctedImage,
      selectedImageValue,
      selectedImageDialog,
      visibility,
      visibilities,
      isShowTimer,
      timerType,
      timerOptions,
      deadlineCompletion,
      todayDate
    };
  }
});
